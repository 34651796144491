/* eslint-disable */
import { useState, useRef, useEffect,forwardRef } from 'react';
import useAuth from 'src/hooks/useAuth';
// import PageTitleWrapper from 'src/components/PageTitleWrapper';
// import { Helmet } from 'react-helmet-async';
// import Footer from 'src/components/Footer';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin, { DayGridView } from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
// import interactionPlugin from '@fullcalendar/interaction';
// import listPlugin from '@fullcalendar/list';
import { useNavigate } from "react-router-dom";
import certifyAxios from 'src/utils/aimAxios';
import moment from 'moment';
import GroupIcon from '@mui/icons-material/Group';
import {
  Grid,
  Drawer,
  Box,
  Card,
  Divider,
  useMediaQuery,
  styled,
  useTheme,
  Typography,
  Dialog,
  DialogContent,
  CircularProgress,
  DialogTitle,
  IconButton,
  Button,
  Slide,
  InputAdornment,
  TextField,
  FormControl,
  Select,
  MenuItem,
  CardContent,
  Stack
} from '@mui/material';

import { useDispatch, useSelector } from 'src/store';
import {
  getEvents,
  updateEvent,
  selectEvent,
  selectRange,
  openDrawerPanel,
  closeDrawerPanel
} from 'src/slices/calendar';
import PageHeader from './PageHeader';

import Actions from './Actions';
import EventDrawer from './EventDrawer';
import FIcon from '@mui/icons-material/Flag'; // Ejemplo de ícono para "F"
import EIcon from '@mui/icons-material/EmojiEmotions'; // Ejemplo de ícono para "E"
import CircleIcon from '@mui/icons-material/Circle'; // Ícono de una bola (puedes personalizar esto)
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SearchIcon from '@mui/icons-material/Search';
const BootstrapDialog = styled(Dialog)(() => ({
  // '& .MuiDialogContent-root': {
  //   padding: theme.spacing(2),
  // },
  // '& .MuiDialogActions-root': {
  //   padding: theme.spacing(1),
  // },
  "& .MuiDialog-container": {
      alignItems: "center"
    }
}));

const FullCalendarWrapper = styled(Box)(
  ({ theme }) => `
    // padding: ${theme.spacing(3)};
    & .fc-license-message {
      display: none;
    }

      .fc-button-primary{
        background-color:transparent !important;
        border-color:transparent !important;
        color:black !important;
        border: none !important;
      }

        .fc-button-primary:focus {
          outline: none !important;
          outline-offset: none !important;
          box-shadow: none !important;
      }

      .fc-header-toolbar{
        background-color:transparent;
        margin-top:5px !important;
        position:absolute !important;
        z-index:2;
        width:96% !important;
        // padding-right:2% !important;
        overflow-y: hidden; // hide vertical
        overflow-x: hidden; // hide horizonta
      }

      .fc th{
      border: none;
      border-bottom: 1px solid #ECECEC;
      background-color:#ECECEC;
      border-radius: 0px 0px 0px 0px; 
      overflow-y: hidden; // hide vertical
        overflow-x: hidden; // hide horizonta
      }

      /*Quitar bordes*/
      // .fc td, .fc th {
      //   border-style: none !important;
      // }
        .fc td {
        background-color: white !important;
          border-radius: 0px 0px 8px 8px; 
          border-style: none !important
        }

        .fc-scrollgrid {
        border: none !important;
      }

      // .fc-scrollgrid td:last-of-type {
      //   border-right: none !important;
      // }
        /*Fin de quitar bordes*/
    .fc {
      .fc-day-today {
        background-color: inherit !important;
        font-weight:bolder !important;
        // font-size: 16px;
      }
      .fc-col-header-cell {
        text-transform: uppercase !important;
        font-weight:lighter;
        padding: ${theme.spacing(1)};
        overflow-y: hidden; // hide vertical
        overflow-x: hidden; // hide horizonta
        // background: ${theme.colors.alpha.black[5]};
      }

      .fc-scroller::-webkit-scrollbar{
        display: none;
      }

    .fc-event-main{
     background-color: transparent;
    }

      // .fc-event{
      //   width: 98px !important;}
    .fc-view {
    background-color: transparent;
      .fc-event { 
        width: 94%;
        float: none;
        // margin: 0 auto;
        // padding-top:3%;
        margin-left:2%;
        // margin-right:2% !important;
        margin-top: 5%;
        border-radius:10px;
        // padding-left:;
    }
}
      
`
);

const selectedEventSelector = (state) => {
  const { events, selectedEventId } = state.calendar;

  if (selectedEventId) {
    return events.find((_event) => _event.id === selectedEventId);
  }
  return null;
};


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function ApplicationsCalendar({altura}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [dayEvents, setDayEvents] = useState([]);
  const { isAuthenticated,user } = useAuth();
  const navigate = useNavigate();
   // eslint-disable-next-line no-unused-vars
 const [diaError,setDiaError] = useState(false);
 const CloseDiaError = (event, reason) =>{
  if (reason && reason === "backdropClick") return;
  setDiaError(false);
}
  // eslint-disable-next-line no-unused-vars
  const [bloqueo,setBloqueo] = useState(false);
  const calendarRef = useRef(null);
  const dispatch = useDispatch();
  // const { events, isDrawerOpen, selectedRange } = useSelector(
  //   (state) => state.calendar
  // );
  const [events,setEvents] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [disciplinas,setDisciplinas] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [disciplina,setDisciplina] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [personales,setPersonales] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [personal,setPersonal] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [busqueda,setBusqueda] = useState("");
  //const selectedEvent = useSelector(selectedEventSelector);
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState('dayGridWeek');
  const [currentWeek, setCurrentWeek] = useState(moment().startOf("isoWeek"));

  const changeWeek = (direction) => {
    if (direction === "prev" && currentWeek.isSame(moment().startOf("isoWeek"), "week")) {
      return; // Evitar retroceder más allá de la semana actual
    }
    const newWeek =
      direction === "next"
        ? moment(currentWeek).add(1, "weeks").startOf("isoWeek")
        : moment(currentWeek).subtract(1, "weeks").startOf("isoWeek");

    setCurrentWeek(newWeek);

    // Si es la semana actual, tomamos el día actual, de lo contrario, el lunes de la nueva semana
    const newSelectedDay = newWeek.isSame(moment(), "week")
      ? moment()
      : newWeek;
      // setSelectedDay(newSelectedDay.toDate());
      setBloqueo(true);
      getClasses(newSelectedDay.toDate(),busqueda,disciplina,personal);
    // setSelectedDay(newSelectedDay);
    // handleDayClick(newSelectedDay.toDate(), events);
  };

  // const handleDateToday = () => {
  //   const calItem = calendarRef.current;

  //   if (calItem) {
  //     const calApi = calItem.getApi();

  //     calApi.today();
  //     setDate(calApi.getDate());
  //   }
  // };

  // const changeView = (changedView) => {
  //   const calItem = calendarRef.current;

  //   if (calItem) {
  //     const calApi = calItem.getApi();
  //     console.log(changedView);
  //     calApi.changeView(changedView);
  //     setView(changedView);
  //   }
  // };

  const handleDayClick = (day, evts) => {
    setSelectedDay(day);
  
    const eventsOfDay = evts.filter(
      (event) => moment(event.start).isSame(day, 'day') && event.status !== 0
    );
  
    setDayEvents(eventsOfDay);
  };

  const handleDatePrev = () => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.prev();
      // setDate(calApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.next();
      // setDate(calApi.getDate());
    }
  };

  // const handleAddClick = () => {
  //   dispatch(openDrawerPanel());
  // };

  // const handleRangeSelect = (arg) => {
  //   const calItem = calendarRef.current;

  //   if (calItem) {
  //     const calApi = calItem.getApi();

  //     calApi.unselect();
  //   }

  //   dispatch(selectRange(arg.start, arg.end));
  // };

  // const handleEventSelect = (arg) => {
  //   dispatch(selectEvent(arg.event.id));
  // };

  // const handleEventResize = async ({ event }) => {
  //   try {
  //     await dispatch(
  //       updateEvent(event.id, {
  //         allDay: event.allDay,
  //         start: event.start,
  //         end: event.end
  //       })
  //     );
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  let diax  = new Date();
  diax.setHours(0,0,0,0);
  const [dia,setDia] = useState(diax);


  const getDisciplinas = async () => {
    const reqObj = [];
    const responseActive = await certifyAxios.get('/person/discipline', reqObj);
    // console.log(responseActive);
    // console.log(categorias);
    // let x = []; 
    // let x = [{
    //   id_categoria:0,
    //   nombre:"Sin Categoria"
    // }];
    // let z = x.concat(responseActive.data.list)
    // console.log(responseActive);
    // setPersonales(responseActive.data.list);
    setDisciplinas(responseActive.data.list);
    //  
  }

  const getClasses = async (dx,busq,dis,per) =>{ 
    let idx;
    if(user === null) idx = 0;
     else idx = user.id_cliente;
    const reqObj = {
      "dia":dx,
      "id_cliente":idx,
      "busqueda":busq,
      "id_disciplina":dis,
      "id_empleado":per
    };
    // console.log(reqObj);
    // setBloqueo(true);
    let ans = [];
    const responseActive = await certifyAxios.post('/service/class/query', reqObj);
    console.log(responseActive);
    for(let i = 0;i<responseActive.data.list.length;i++){
      for(let j=0;j<responseActive.data.list[i].sessions.length;j++){
        let x = responseActive.data.list[i].sessions[j];
        // console.log(x);
        ans.push({
          "id_sesion":x.id_sesion,
          "name":x.nombre,
          "disciplineName":x.disciplina,
          "start":x.fecha_inicio,
          "duracion":0,
          "capacity":x.aforo,
          "reserves":x.ocupado,
          "instructorName":x.personal,
          "status":x.estado_clase === 'Abierta' ? 1:0,
          "color":"#F7F7F7",
          "textColor":"black",
          "reserved":x.reservado
        });
      }
    }
    
    setEvents(ans);
    handleDayClick(dx,ans);
    // console.log(responseActive);
    // setResultados([]);
    // setResultados(responseActive.data.list);
    // setBloqueo(false);
    // console.log(responseActive);

  }

  const getPersonal = async () => {
    
    const reqObj = {
    };
    const responseActive = await certifyAxios.post('/person/personal/query/trainer', reqObj);
    // console.log(responseActive);
    // console.log(categorias);
    // let x = []; 
    // let x = [{
    //   id_categoria:0,
    //   nombre:"Sin Categoria"
    // }];
    // let z = x.concat(responseActive.data.list)
    // console.log(responseActive);
    setPersonales(responseActive.data.list);
    // setDisciplinas(responseActive.data.list);
    //  
  }

  // useEffect(() => {
  //   if (disciplina) {
  //     getClasses(dia); 
  //   }
  // }, [disciplina]); 


  // useEffect(() => {
  //   if (personal) {
  //     getClasses(dia); 
  //   }
  // }, [personal]); 
  const fechaEspanol = (fhr)=>{
    const mesEnEspanol = fhr.toLocaleDateString("es-PE", { month: "long" });
    
    return mesEnEspanol.charAt(0).toUpperCase() + mesEnEspanol.slice(1);
  }

  useEffect(() => {
    let time = 0;
    if(busqueda==="") time = 0;
    const timeOutId = setTimeout(() => getClasses(dia,busqueda,disciplina,personal), time);
    return () => clearTimeout(timeOutId);
  }, [busqueda]);

  useEffect(() => {
    // console.log(dia);
    setBloqueo(true);
    getClasses(dia,busqueda,disciplina,personal);
    getDisciplinas();
    getPersonal();
    // setBloqueo(false);
    // getPlans();
      // setEvents([
      //   {
      //     "name": "RIDE CESAR R.",
      //     "disciplineName": "CYCLING CHACARILLA",
      //     "start": "2024-09-30T06:00:00-05:00",
      //     "duracion": 50,
      //     "capacity": 30,
      //     "reserves": 1,
      //     "instructorName": "CESAR R.",
      //     "status": 1,
      //     "color":"#F7F7F7",
      //     "textColor":"black"
      //   },

      // ]);
  }, []);
  
  useEffect(()=>{
    if(disciplinas.length>0 && personales.length>0){
      setBloqueo(false);
    }

  },[events,disciplinas,personales])


  const goClase = (clase)=>{
    // console.log(clase);
    if(!isAuthenticated){
      // console.log('asdsadsad');
      setDiaError(true);
      return;
    }
    else{
      let id = clase.id_sesion;
      navigate(`/reserve/${id}`);
    }
  }


  const renderEventContent = (eventInfo) => {
    //console.log(eventInfo.event._def.extendedProps)
    // console.log(eventInfo.event._instance.range.start)
    return (
      // ,boxShadow:'0 0 5px 0px black'
           <div style={{height:'170px',borderRadius:8,border:0,
            paddingTop:'5%',boxShadow:'0 0 2px 0px black',
            cursor: eventInfo.event._def.extendedProps.status === 1 ? 'pointer':''
           }}
           onClick={()=>{
             if(eventInfo.event._def.extendedProps.status === 1)
              {
                goClase(eventInfo.event._def.extendedProps);
              }
           }}
           >
            {eventInfo.event._def.extendedProps.status == 1 ? (
              <>
                  <Typography noWrap style={{fontSize:'14px',fontWeight:'bold'}}>
                  &nbsp;{eventInfo.event._def.extendedProps.name}
                  </Typography>
                  <Typography noWrap style={{fontSize:'13px',fontWeight:'lighter'}}>
                  &nbsp;{eventInfo.event._def.extendedProps.disciplineName}
                  </Typography>
                  <Typography noWrap style={{fontSize:'13px',fontWeight:'lighter'}}>
                  &nbsp;{eventInfo.event._def.extendedProps.instructorName
                  }
                  </Typography>
                  <div style={{position:'absolute',bottom:7,width:'100%'}}>
                  <Typography noWrap style={{fontSize:'13px',fontWeight:'bold'}}>
                  &nbsp;{moment(eventInfo.event._instance.range.start).utc().format('hh:mm A')
                  }
                  </Typography>
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                  <Typography noWrap style={{fontSize:'13px',fontWeight:'lighter'}}>
                  &nbsp;
                  </Typography >
                  <div
                    style={{
                      justifyContent: 'flex-end',
                      display: 'flex',
                      alignItems: 'center', // Alinea verticalmente todos los elementos
                    }}
                  >
                    {eventInfo.event._def.extendedProps.reserves === eventInfo.event._def.extendedProps.capacity ? (
                      <Box
                      sx={{
                        width: '18px', // Tamaño del cuadro
                        height: '18px', // Tamaño del cuadro
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#EF3533', // Color de fondo
                        borderRadius: '4px', // Opcional: hace que los bordes sean redondeados
                        mr:1
                      }}
                    >
                      <Typography sx={{ color: '#FFFFFF', fontSize:'12px'}}>
                        F
                      </Typography>
                    </Box>
                    ) : (
                      <></>
                    )}
                    
                    {eventInfo.event._def.extendedProps.reserved === 1 ? (
                      <Box
                        sx={{
                          width: '18px', // Tamaño del cuadro
                          height: '18px', // Tamaño del cuadro
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: '#3BF6AE', // Color de fondo
                          borderRadius: '4px', // Opcional: hace que los bordes sean redondeados
                          mr: 1,
                        }}
                      >
                        <Typography sx={{ color: '#FFFFFF', fontSize: '12px' }}>R</Typography>
                      </Box>
                    ) : (
                      <></>
                    )}
                    <GroupIcon style={{ transform: 'rotateY(180deg)' }} />
                    &nbsp;
                    <Typography
                      noWrap
                      style={{
                        fontSize: '14px',
                        fontWeight: 'lighter',
                      }}
                    >
                      {eventInfo.event._def.extendedProps.reserves}/
                      {eventInfo.event._def.extendedProps.capacity} &nbsp;
                    </Typography>
                  </div>
                  </div>
                  </div>
            </>
            ):(
            <>
              <Typography noWrap style={{fontSize:'14px',fontWeight:'bold',color:'#9B9B9B'}}>
              &nbsp;{eventInfo.event._def.extendedProps.name}
              </Typography>
              <Typography noWrap style={{fontSize:'13px',fontWeight:'lighter',color:'#9B9B9B'}}>
              &nbsp;{eventInfo.event._def.extendedProps.disciplineName}
              </Typography>
              <Typography noWrap style={{fontSize:'13px',fontWeight:'lighter',color:'#9B9B9B'}}>
              &nbsp;{eventInfo.event._def.extendedProps.instructorName
              }
              </Typography>
              <div style={{position:'absolute',bottom:7,width:'100%'}}>
              <Typography noWrap style={{fontSize:'13px',fontWeight:'bold',color:'#9B9B9B'}}>
              &nbsp;{moment(eventInfo.event._instance.range.start).utc().format('hh:mm A')
              }
              </Typography>
              <div style={{display:'flex',justifyContent:'space-between'}}>
              <Typography noWrap style={{fontSize:'13px',fontWeight:'lighter',color:'#9B9B9B'}}>
              &nbsp;
              </Typography >
                 {/* <div style={{justifyContent:'flex-end',display:'flex'}}>
                     <GroupIcon style = {{transform: 'rotateY(180deg)'}}/>&nbsp;
                     <Typography style={{fontSize:'14px',fontWeight:'lighter'}}>
                     {eventInfo.event._def.extendedProps.reserves}/
                     {eventInfo.event._def.extendedProps.capacity} &nbsp;
                     </Typography>
                 </div> */}
              </div>
              </div>
        </>)}
           
           </div>
    )
};
  return (
    <>
    {/* DIALOG SIN MEMBRESIAS */}
    < Dialog
    open={diaError}
    TransitionComponent={Transition}
  //   TransitionProps={{
  //     style: {
  //         transitionDelay: 500,
  //     }
  // }}
    fullWidth
    maxWidth="xs"
    // keepMounted
    onClose={CloseDiaError}
    aria-describedby="alert-dialog-slide-description"
  >
  {/* <DialogTitle sx={{ m: 0, p: 2,pb:1}} id="customized-dialog-title" style={{fontWeight:'bold',fontSize:'17px'}}>
    Error al realizar la reserva
  </DialogTitle> */}
  <IconButton
    aria-label="close"
    onClick={CloseDiaError}
    sx={{
      position: 'absolute',
      right: 8,
      top: 8,
      color: (theme) => theme.palette.grey[500],
    }}
  >
    <CloseIcon />
  </IconButton>
  <DialogContent dividers>
  <Box sx={{ pt:0.5
  // ,ml:7,mr:7
}}      
  >
      <Box 
        sx={{mt:0}}>
          {/* <Box style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
              <EmailIcon sx={{height:100,width:100}}/>
          </Box> */}
          

          <Box style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
              <ErrorOutlineIcon sx={{height:100,width:100,color:'orange'}}/>
          </Box>
          <Typography align='center' sx={{fontSize:'19px',mb:1,fontWeight:'bold'}}>
          No has iniciado sesión
          </Typography>
          <Typography align='center' sx={{mb:2,fontSize:'14px'}} >
            <Typography display="inline" sx={{fontSize:'14px'}}>
            Para reservar una clase, primero debes iniciar sesión.
            </Typography>
            </Typography>
          <Box sx={{mt:2,display:'flex',justifyContent:'center',alignContent:'center'}}>
            {/* <Button autoFocus variant='contained' sx={{mr:3}} style={{color:'white',backgroundColor:'red'}}
              onClick={()=>{
                setDiaError(false);
              }}
            >
            CANCELAR
            </Button> */}
            <Button autoFocus variant='contained' style={{color:'white',backgroundColor:'black'}}
            onClick={()=>{
              setDiaError(false);
            }}
            >
             OK
            </Button>
          </Box>
      </Box>
      </Box>
  </DialogContent>
  
  </Dialog>
  {/* //////////// */}
    <BootstrapDialog
        maxWidth="xs"
        fullWidth
        open={bloqueo}
        // onClose={handleCloseConfirmacion}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <DialogContent >
          <Box style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
            <CircularProgress color="primary" size="6rem"/>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    
    {
      isMobile ? (
      <>
     <Box>
      <Typography align="center" sx={{mb:2,p:0,mt:0,fontSize:'20px',mt:1}}>
        {fechaEspanol(selectedDay)}
        </Typography>
     <Box
  sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // overflow: "hidden",
    width: "100%",
    mb:2
  }}
>

  {/* Botón de semana anterior */}
  <Button
    onClick={() => changeWeek("prev")}
    disabled={currentWeek.isSame(moment().startOf("isoWeek"), "week")}
    sx={{
      minWidth: 0,
      // flexShrink: 0,
      p:0,
      color:"#000000",
      "&:hover": {
            backgroundColor: "transparent",
          },
          "&:focus": {
            backgroundColor: "transparent",
            // color: isSelected ? "#FFFFFF" : "#000000",
            // boxShadow: "none",
            // border: "1px solid",
            // outline:"none"
          },
          "&:active": {
            backgroundColor: "transparent",
            // color: isSelected ? "#FFFFFF" : "#000000",
            // boxShadow: "none",
            // transform:"none"
          },
    }}
  >
    <ArrowForwardIos fontSize="medium" sx={{ transform: "scaleX(-1)" }}/>
  </Button>

  {/* Contenedor de los botones de los días */}
  <Box
    sx={{
      display: "flex",
      pl:0.5,
      pr:0.5,
      // flexGrow: 1,
      // justifyContent: "center",
      gap: 0.7, // Espacio reducido para evitar desbordamiento
      // maxWidth: "calc(100% - 80px)", // Restamos el ancho de los botones de navegación
    }}
  >
    {Array.from({ length: 7 }).map((_, index) => {
      const day = moment(currentWeek).add(index, "days");
      const dayNames = ["LUN", "MAR", "MIÉ", "JUE", "VIE", "SÁB", "DOM"];
      const isSelected = moment(selectedDay).isSame(day, "day");
      return (
        <Button
        key={index}
        variant="outlined"
        onClick={() => {
          setSelectedDay(day);
          handleDayClick(day.toDate(), events);
        }}
        sx={{
          minWidth: 35,
          height: 35,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 0,
          borderRadius: 0.5,
          border: "1px solid #000000",
          backgroundColor: isSelected ? "#000000" : "#FFFFFF",
          color: isSelected ? "#FFFFFF" : "#000000",
          boxShadow: "none",
          transition: "none",
          outline: "none",
          "&:hover, &:focus, &:active": {
            backgroundColor: isSelected ? "#000000" : "#FFFFFF",
            color: isSelected ? "#FFFFFF" : "#000000",
            boxShadow: "none",
            border: "1px solid #000000",
            minWidth: 35, // Fuerza el mismo ancho en todos los estados
            height: 35, // Fuerza la misma altura en todos los estados
          },
        }}
      >
        <Typography sx={{ lineHeight: 1, fontSize: "12px" }}>{dayNames[index]}</Typography>
        <Typography sx={{ lineHeight: 1, fontSize: "12px" }}>{day.format("D")}</Typography>
      </Button>
      );
    })}
  </Box>

  {/* Botón de semana siguiente */}
  <Button
    onClick={() => changeWeek("next")}
    sx={{
      minWidth: 0,
      p:0,
      color:"#000000",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        backgroundColor: "transparent",
        // color: isSelected ? "#FFFFFF" : "#000000",
        // boxShadow: "none",
        // border: "1px solid",
        // outline:"none"
      },
      "&:active": {
        backgroundColor: "transparent",
        // color: isSelected ? "#FFFFFF" : "#000000",
        // boxShadow: "none",
        // transform:"none"
      },
    }}
  >
    <ArrowForwardIos fontSize="medium" />
  </Button>
</Box>


          {/* Lista de eventos */}
          <Grid container spacing={1.5} sx={{p:1,pt:0,mb:1}}>
            {dayEvents.length > 0 ? (
              dayEvents.map((event, index) => (
              <Grid item xs={12} key={index} sx={{ p: 0 }}>
                <Card  onClick={()=>{
                  goClase(event);
                }}
                sx={{
                  // margin:2,
                  // padding:2,
                  p: 0.5,
                  pb: 0,
                  // pt: 0,
                  borderRadius: "12px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #ddd",
                }}
              >
                <CardContent sx={{ "&:last-child": { p: 1 } }}>
                  <Stack direction="row" alignItems="stretch">
                    {/* Sección izquierda */}
                    <Stack spacing={0.25} alignItems="flex-start">
                      <Typography sx={{ fontSize: "13px" }}>
                        <b>{moment(event.start).format('hh:mm A')}</b>
                      </Typography>
                      {/* <Typography sx={{ fontSize: "13px" }}>
                        <b>{formatAMPM(new Date(itx.fecha))}</b>
                      </Typography> */}
                    </Stack>
            
                    {/* Divider vertical extendido */}
                    <Divider orientation="vertical" sx={{ mr: 1, ml: 1}} />
            
                    {/* Sección derecha con botón dentro */}
                    <Stack direction="column" alignItems="left" flexGrow={1} justifyContent="space-between">
                      <Box>
                        <Typography sx={{ fontSize: "14px" }}>
                          <b>{event.name}</b>
                        </Typography>
                        {/* <Typography sx={{ fontSize: "13px" }}>{itx.personal}</Typography> */}
                        <Typography sx={{ fontSize: "13px" }}>{event.disciplineName}</Typography>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                        {/* Texto alineado a la izquierda */}
                        <Typography sx={{ fontSize: "13px" }}>
                          {event.instructorName}
                        </Typography>

                        {/* Contenedor derecho alineado a la derecha */}
                        <Box display="flex" alignItems="center">
                          {event.reserved === 1 && (
                            <Box
                              sx={{
                                width: '18px',
                                height: '18px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#3BF6AE',
                                borderRadius: '4px',
                                mr: 1,
                              }}
                            >
                              <Typography sx={{ color: '#FFFFFF', fontSize: '12px' }}>R</Typography>
                            </Box>
                          )}
                          
                          <GroupIcon style={{ transform: 'rotateY(180deg)' }} />
                          &nbsp;
                          <Typography
                            noWrap
                            sx={{
                              fontSize: '14px',
                              fontWeight: 'lighter',
                            }}
                          >
                            {event.reserves}/{event.capacity} &nbsp;
                          </Typography>
                        </Box>
                      </Box>

                      </Box>
            
                     
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
              </Grid>
              ))
            ) : (<>
            <Grid 
                item 
                xs={12}  
                sx={{ 
                  p: 0, 
                  minHeight: '150px', 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center' 
                }}
              >
                <Typography align="center" sx={{ fontSize: '16px' }}>
                  <b>No hay clases disponibles para este día</b>
                </Typography>
              </Grid>

              </>
            )}
          </Grid>
        </Box>
      </>

      ):
      (<>
<FullCalendarWrapper style={{paddingLeft:'2%',paddingRight:'2%',backgroundColor:'transparent'}}>
     <Box
        sx={{
          background: 'linear-gradient(to top, #ECECEC, #FFFFFF)',
          borderRadius: '8px 8px 0px 0px',
          width: '100%',
          padding: '18px', // Ajusta el padding según sea necesario
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center', // Asegura alineación vertical
          gap: '16px', // Espaciado uniforme entre los elementos,
          display:'flex',
          flexDirection:'column'
        }}
      >
   <Box sx={{ 
   display: 'flex',
   flexWrap: 'wrap', // Permite que los elementos se ajusten a la siguiente línea si no hay espacio.
   justifyContent: 'center', // Centra los elementos horizontalmente.
   gap: 2, // Espaciado entre elementos.
   width: '100%', // Asegura que el contenedor ocupe el ancho completo.
   }}>
      {/* Clase reservada */}
      <Box sx={{ display: 'flex', alignItems: 'center' ,justifyContent:'center',alignContent:'center'}}>
            <Box
            sx={{
              width: '18px', // Tamaño del cuadro
              height: '18px', // Tamaño del cuadro
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#3BF6AE', // Color de fondo
              borderRadius: '4px', // Opcional: hace que los bordes sean redondeados
              mr:1
            }}
          >
            <Typography sx={{ color: '#FFFFFF', fontSize:'12px'}}>
              R
            </Typography>
          </Box>
        <Typography variant="body1">Clase reservada</Typography>
      </Box>

      {/* Clase Llena */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
            sx={{
              width: '18px', // Tamaño del cuadro
              height: '18px', // Tamaño del cuadro
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#EF3533', // Color de fondo
              borderRadius: '4px', // Opcional: hace que los bordes sean redondeados
              mr:1
            }}
          >
            <Typography sx={{ color: '#FFFFFF', fontSize:'12px'}}>
              F
            </Typography>
          </Box>
        <Typography variant="body1">Clase Llena</Typography>
      </Box>

      {/* En lista de espera */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
            sx={{
              width: '18px', // Tamaño del cuadro
              height: '18px', // Tamaño del cuadro
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#FFD33C', // Color de fondo
              borderRadius: '4px', // Opcional: hace que los bordes sean redondeados
              mr:1
            }}
          >
            <Typography sx={{ color: '#FFFFFF', fontSize:'12px'}}>
              E
            </Typography>
          </Box>
        <Typography variant="body1">En lista de espera</Typography>
      </Box>

      {/* Aforo */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <GroupIcon sx={{ color: 'black', marginRight: 1 }} />
        <Typography variant="body1">Aforo</Typography>
      </Box>

      {/* Clase en curso */}
      {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CircleIcon sx={{ color: 'red', marginRight: 1 }} />
        <Typography variant="body1">Clase en curso</Typography>
      </Box> */}
    </Box>
   <Box sx={{ display: 'flex', justifyContent: 'center', width:'100%',maxWidth:'100%',gap:4,alignItems:'center'}}> 
        {/* TextField */}
        <TextField
        value={busqueda} 
        onChange={(event) =>{
          setBusqueda(event.target.value);
          // handlePage(1,event.target.value);
        }}
          variant="outlined"
          placeholder="Buscar"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }
        }
          sx={{
            flex: '1', // Asegura que ocupe espacio proporcional si es necesario
            maxWidth: '30%', // Limita el ancho si es necesario
            backgroundColor: '#FFFFFF',
            borderRadius: '8px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              '& fieldset': {
                borderColor: '#E5E5E5',
              },
              '&:hover fieldset': {
                borderColor: '#E5E5E5 !important',
              },
              '&.Mui-focused fieldset': {
                border: '1px solid #E5E5E5',
              },
            },
            '&:hover': {
              backgroundColor: '#FFFFFF',
            },
          }}
        />

               
                  <FormControl
  variant="outlined"
  sx={{
    flex: '1',
    maxWidth: '20%',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      '& fieldset': {
        borderColor: '#E5E5E5',
      },
      '&:hover fieldset': {
        borderColor: '#E5E5E5 !important',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #E5E5E5',
      },
    },
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  }}
>
  <Select
    value={disciplina}
    onChange={(event) => {
      setDisciplina(event.target.value);
      getClasses(dia,busqueda,event.target.value,personal);
    }
    }
    displayEmpty
    renderValue={(selected) => {
      if (selected === "") {
        return (
          <span style={{ color: '#A2A9B7' }}>
            Disciplinas
          </span>
        );
      }
      const selectedDiscipline = disciplinas.find(cat => cat.id_disciplina === selected);
      return selectedDiscipline ? `${selectedDiscipline.nombre}` : '';
    }}
    sx={{
      textAlign: 'left', // Asegura que el texto se alinee a la izquierda
    }}
    MenuProps={{
      disablePortal: true, // Mantiene el menú dentro del mismo árbol DOM
      anchorOrigin: {
        vertical: 'bottom', // Ancla el menú al borde inferior del Select
        horizontal: 'left', // Alinea el menú al lado izquierdo del Select
      },
      transformOrigin: {
        vertical: 'top', // Mantiene el origen del menú en la parte superior
        horizontal: 'left', // Alinea el origen del menú al lado izquierdo
      },
      PaperProps: {
        style: {
          maxHeight: '200px', // Ajusta la altura máxima para hacer scroll si es necesario
          overflowY: 'auto', // Permite el desplazamiento vertical si las opciones son muchas
        },
      },
    }}
  >
    {
      // Muestra solo las disciplinas como opciones
      disciplinas.map((cat) => (
        <MenuItem value={cat.id_disciplina} key={cat.id_disciplina}>
          {`${cat.nombre}`}
        </MenuItem>
      ))
    }
  </Select>
</FormControl>


                <FormControl
  variant="outlined"
  sx={{
    flex: '1',
    maxWidth: '20%',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      '& fieldset': {
        borderColor: '#E5E5E5',
      },
      '&:hover fieldset': {
        borderColor: '#E5E5E5 !important',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #E5E5E5',
      },
    },
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  }}
>
  <Select
    value={personal}
    onChange={(event) => {
      setPersonal(event.target.value);
      getClasses(dia,busqueda,disciplina,event.target.value);
    }}
    displayEmpty
    renderValue={(selected) => {
      if (selected === "") {
        return (
          <span style={{ color: '#A2A9B7' }}>
            Instructores
          </span>
        );
      }
      const selectedPersonal = personales.find(cat => cat.id_empleado === selected);
      return selectedPersonal ? `${selectedPersonal.nombres} ${selectedPersonal.apellido_p}` : '';
    }}
    sx={{
      textAlign: 'left', // Asegura que el texto se alinee a la izquierda
    }}
    MenuProps={{
      disablePortal: true, // Mantiene el menú dentro del mismo árbol DOM
      anchorOrigin: {
        vertical: 'bottom', // Ancla el menú al borde inferior del Select
        horizontal: 'left', // Alinea el menú al lado izquierdo del Select
      },
      transformOrigin: {
        vertical: 'top', // Mantiene el origen del menú en la parte superior
        horizontal: 'left', // Alinea el origen del menú al lado izquierdo
      },
      PaperProps: {
        style: {
          maxHeight: '200px', // Ajusta la altura máxima para hacer scroll si es necesario
          overflowY: 'auto', // Permite el desplazamiento vertical si las opciones son muchas
        },
      },
    }}
  >
    {
      // Muestra solo las disciplinas como opciones
      personales.map((cat) => (
        <MenuItem value={cat.id_empleado} key={cat.id_empleado}>
          {`${cat.nombres} ${cat.apellido_p}`}
        </MenuItem>
      ))
    }
  </Select>
</FormControl>
<Button
  variant='outlined'
  sx={{
    color: '#000000', // Color del texto
    borderColor: '#000000', // Color del borde
    fontWeight: 'lighter',
    height: '36px', // Ajusta la altura del botón
    width: '120px', // Ajusta el ancho del botón
    '&:hover': {
      borderColor: '#000000', // Mantiene el color del borde igual al pasar el ratón
      backgroundColor: 'transparent', // Mantiene el color de fondo transparente
    },
    '&:active': {
      borderColor: '#000000', // Mantiene el color del borde al presionar el botón
      backgroundColor: 'transparent', // Mantiene el color de fondo transparente al presionar
    },
  }}
  onClick={()=>{
    setBusqueda("");
    setDisciplina("");
    setPersonal("");
    getClasses(dia,"","","");
  }}
>

  LIMPIAR
</Button>
</Box>
      </Box>
              <FullCalendar
                // headerToolbar={ (Typography>XD</Typography>)}
                firstDay={1}
                allDayMaintainDuration
                initialDate={date}
                initialView={view}
                locale='es'
                allDaySlot= {false}
                slotMinTime= '00:00:00'
                slotMaxTime= '24:00:00'
                // droppable
                // editable
                // nowIndicator={false}
                eventDisplay="block"
                eventContent={renderEventContent}
                // eventClick={handleEventSelect}
                // eventDrop={handleEventDrop}
                dayMaxEventRows={30}
                eventResizableFromStart
                // eventResize={handleEventResize}
                events={events}
                customButtons={{
                  nextButton: {
                      text: '',
                      icon:'chevron-right',
                      click: function() {
                          // alert('clicked the custom button!');
                          handleDateNext();
                          setBloqueo(true);
                          let x = dia;
                          x.setDate(x.getDate()+7);
                          getClasses(x,busqueda,disciplina,personal);
                          setDia(x);
                          setBloqueo(false);
                      },
                  },
                  prevButton: {
                    text: '',
                    icon:'chevron-left',
                    click: function() {
                        // alert('clicked the custom button!');
                        handleDatePrev();
                        setBloqueo(true);
                        let x = dia;
                        x.setDate(x.getDate()-7);
                        getClasses(x,busqueda,disciplina,personal);
                        setDia(x);
                        setBloqueo(false);
                    },
                },
              }}
                headerToolbar={{start:'prevButton',center:'',end:'nextButton'}}

                // headerToolbar={true}
                height={altura === "alta" ? 1000:700}
                // contentHeight={'auto'}
                ref={calendarRef}
                rerenderDelay={10}
                // select={handleRangeSelect}
                // selectable
                weekends
                displayEventTime='false'
                slotDuration={'24:00:00'}
                // slotMinWidth= {30}
                
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  // interactionPlugin,
                  // listPlugin
                ]}
              />
     </FullCalendarWrapper>
      </>)
    }         
    </>
  );
}

export default ApplicationsCalendar;
