import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Box, useTheme, useMediaQuery } from '@mui/material';

const Scrollbar = ({ className, children, autoHide = false, ...rest }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detecta si es vista móvil

  return (
    <>
      {isMobile ? (
        // Vista móvil: Scrollbar no ocupa espacio y no afecta layout
        <Box
          sx={{
            width: '100%', // Asegura que ocupe el espacio completo sin afectar layout
            height: '100%', // Evita afectar la altura
            position: 'relative', // Mantiene contenido en posición sin desplazar
            // overflow: 'auto', // Scroll nativo para móvil sin estilos extras
          }}
        >
          {children}
        </Box>
      ) : (
        // Vista escritorio: Scrollbar con funcionalidad completa
        <Scrollbars
        autoHide={autoHide}
        hideTracksWhenNotNeeded // Oculta completamente el espacio del scrollbar
        renderThumbVertical={() => (
          <Box
            sx={{
              background: '#888888',
              borderRadius: '6px',
              width: '6px',
              transition: `${theme.transitions.create(['background'])}`,
              '&:hover': {
                background: '#555555',
              },
            }}
          />
        )}
        renderTrackVertical={() => (
          <div
            style={{
              position: 'absolute',
              width: '6px',
              right: '0px',
              bottom: '0px',
              top: '0px',
              borderRadius: '6px',
              background: '#FFFFFF',
            }}
          />
        )}
        {...rest}
      >
        {children}
      </Scrollbars>
      )}
    </>
  );
};

Scrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  autoHide: PropTypes.bool,
};

export default Scrollbar;
