import { 
  Box,
  Button, 
  IconButton,
  MenuItem,
  Divider,
  Popper,
  MenuList,
  Grow,
  Paper,
  ClickAwayListener,
  Dialog,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  Link,
  CircularProgress,
  DialogActions,
  Slide,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  alpha,
  styled,
  Zoom,
  Stepper,
  Step,
  StepLabel
  // Menu
  // FormControl,
  // Select
} from '@mui/material';
import { useSnackbar } from 'notistack';
import certifyAxios from 'src/utils/aimAxios';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useState,useRef,forwardRef,useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// eslint-disable-next-line no-unused-vars
import EmailIcon from '@mui/icons-material/Email';
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const RequiredLabel = ({ text, required = false, style = {}, ...props }) => {
  return (
    <Typography style={{ fontWeight: 'bold', ...style }} {...props}>
      {text}
      {required && <span style={{ color: 'red' }}> *</span>}
    </Typography>
  );
};

const BootstrapDialog = styled(Dialog)(() => ({
  // '& .MuiDialogContent-root': {
  //   padding: theme.spacing(2),
  // },
  // '& .MuiDialogActions-root': {
  //   padding: theme.spacing(1),
  // },
  "& .MuiDialog-container": {
      alignItems: "center"
    }
}));


function HeaderButtons({qu}) {

  const ALPHA_NUMERIC_DASH_REGEX = /^[0-9]+$/;
  const { enqueueSnackbar } = useSnackbar();
  const { logout,isAuthenticated,login,user } = useAuth();
  const ButtonRef = useRef(null);
  const MenuRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [anchorEl, setAnchorEl] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [menuOpen,setMenuOpen] = useState(false);
  const [pop,setPop] = useState(false);
  const [pop2,setPop2] = useState(false);
  const [userx,setUser] = useState('');
  const [pass,setPass] = useState('');
  const [load,setLoad] = useState(false);
  const [mensaje,setMensaje] = useState('');
  const [msregistro,setMSRegistro] = useState('');
  const navigate = useNavigate();
  const [dialogo,setDialogo] = useState(false);
  const [diaContra,setDiaContra] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // eslint-disable-next-line no-unused-vars
  const [menuAnchor, setMenuAnchor] = useState(null);
  // Variables para el formulario partido en moviles
  // eslint-disable-next-line no-unused-vars
  const [activeStep, setActiveStep] = useState(0); // Paso actual del formulario
  // eslint-disable-next-line no-unused-vars
  const steps = ['', ''];

  const esMayorDeEdad = (fechaNacimiento) => {
    // Convertimos la fecha de nacimiento a UTC sin considerar la zona horaria del navegador
    const [year, month, day] = fechaNacimiento.split('-').map(Number);
    const fechaNac = new Date(Date.UTC(year, month - 1, day)); // Restamos 1 al mes porque en JavaScript los meses van de 0-11

    // Obtener la fecha actual en UTC
    const ahora = new Date();
    const fechaActualUTC = new Date(Date.UTC(
        ahora.getUTCFullYear(),
        ahora.getUTCMonth(),
        ahora.getUTCDate()
    ));

    // Calcula la diferencia en años
    let edad = fechaActualUTC.getUTCFullYear() - fechaNac.getUTCFullYear();

    // Verifica si ya cumplió años este año o no (considerando solo la fecha, sin horas)
    if (
        fechaActualUTC.getUTCMonth() < fechaNac.getUTCMonth() || 
        (fechaActualUTC.getUTCMonth() === fechaNac.getUTCMonth() && fechaActualUTC.getUTCDate() < fechaNac.getUTCDate())
    ) {
        edad--; // Aún no ha cumplido años este año
    }

    return edad >= 18;
};


  // eslint-disable-next-line no-unused-vars
  const handleNext = (stepi) => {
  if(stepi === 1){
   if(nombres === ''){
      setMSRegistro('Por favor ingrese sus nombres');
      return;
  }
  else if(apellidos === '' ){
    setMSRegistro('Por favor ingrese sus apellidos');
      return;
  }
  else if(nacimiento === '' ){
    setMSRegistro('Por favor ingrese su fecha de nacimiento');
      return;
  }
  else if(!esMayorDeEdad(nacimiento)){
    setMSRegistro('Debes ser mayor de edad para poder registrarse');
      return;
  }
  else if(numero === '' ){
    setMSRegistro('Por favor ingrese su número de documento');
      return;
  }
}
else if (stepi ===2){

  if(corr === '' || !validateEmail(corr)){
    setMSRegistro('Por favor ingrese un correo electrónico válido');
      return;
  }
  else if(con1 === ''){
    setMSRegistro('Por favor ingrese una contraseña');
      return;
  }
  else if(con2 === ''){
    setMSRegistro('Por favor confirme su contraseña');
      return;
  }
  else if(con1!==con2){
    setMSRegistro('Las contraseñas no coinciden');
      return;
  }
  else if(termino!==true){
    setMSRegistro('Por favor acepte los términos y condiciones');
      return;
  }
}

    setMSRegistro('');
    setActiveStep((prevStep) => prevStep + 1);
  };


  // Función para retroceder al paso anterior
  // eslint-disable-next-line no-unused-vars
  const handleBack = () => {
    setMSRegistro('');
    setActiveStep((prevStep) => prevStep - 1);
  };

  const [registro,setRegistro] = useState(false);
 // eslint-disable-next-line no-unused-vars
  const [celular,setCelular] = useState('');
  const [tipoDoc,setTipoDoc] = useState(1);
    // eslint-disable-next-line no-unused-vars
  const [tipoGenero,setTipoGenero] = useState('');
  const [tipoShoe,setTipoShoe] = useState('');
    // eslint-disable-next-line no-unused-vars
  const [Docs,setDocs] = useState([
    {
      nombre:"DNI",
      id:1
    },
    {
      nombre:"CE",
      id:3
    },
    {
      nombre: "Pasaporte",
      id:2
    }
  ]);
  // eslint-disable-next-line no-unused-vars
  const [Generos,setGeneros] = useState([
    {
      nombre:"Femenino",
      id:2
    },
    {
      nombre:"Masculino",
      id:1
    },
    {
      nombre: "Otros",
      id:3 // Si género es 3, se pondra en null en el backend.
    }
  ]);
  const [Shoes,setShoes] = useState([]);
  const [nacimiento, setNacimiento] = useState('');
  const [nombres,setNombres] = useState('');
  const [apellidos,setApellidos] = useState('');
  const [numero,setNumero] =useState('');
  const [corr,setCorr] = useState('');
  const [con1,setCon1] = useState('');
  const [con2,setCon2] = useState('');
  const [termino,setTermino] = useState(false);

  // Dialogod de envio
  const [envio,setEnvio] = useState(false);
  const [envioCon,setEnvioCon] = useState(false);
  const [bloqueo,setBloqueo] = useState(false);

  const CloseEnvio = (event, reason) =>{
    if (reason && reason === "backdropClick") return;
    setEnvio(false);
  }

  const CloseEnvioCon = (event, reason) =>{
    if (reason && reason === "backdropClick") return;
    setEnvioCon(false);
  }

  const CloseDialog = (event, reason) =>{
    if (reason && reason === "backdropClick") return;
    setDialogo(false);
    setUser('');
    setPass('');
    setMensaje('');
    setLoad(false);
  }

  const CloseDiaContra = (event, reason) =>{
    if (reason && reason === "backdropClick") return;
    setDiaContra(false);
    setUser('');
    setPass('');
    setMensaje('');
    setLoad(false);
  }


  const CloseRegistro= (event, reason) =>{
    if (reason && reason === "backdropClick") return;
    setRegistro(false);
    setNombres('');
    setApellidos('');
    setTipoDoc(1);
    setTipoGenero('');
    setTipoShoe('');
    setCelular('');
    setNacimiento('');
    setNumero('');
    setCorr('');
    setCon1('');
    setCon2('');
    setTermino(false);
    setMSRegistro(false);
    setActiveStep(0)
    // setUser('');
    // setPass('');
    // setMensaje('');
    // setLoad(false);
  }

  const errorCreacion = (message)=>{
    enqueueSnackbar(`${message}`, {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: Zoom,
      autoHideDuration: 5000
    });
  }

// eslint-disable-next-line no-unused-vars
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () =>{
    setAnchorEl(null);
    setPop(false);
  }
  const handleClose2 = () =>{
    setAnchorEl(null);
    setPop2(false);
  }
   // eslint-disable-next-line no-unused-vars
  const handleLogout = async () => {
    try {
      await logout();
      // navigate('');
      // window.location.replace('https://inf227i4.inf.pucp.edu.pe/');
    } catch (err) {
      console.error(err);
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleLogIn = async () =>{
    try {
      // console.log("xd");
      if(userx === '' || !validateEmail(userx)){
          setMensaje('Por favor ingrese un correo válido');
          return;
      }
      else if(pass === '' ){
          setMensaje('Por favor ingrese su contraseña');
          return;
      }
      setLoad(true);
      // if(user === '' || pass ==='') {setLoad(false); return;}
      const res = await login(userx,pass);
      if(res==='err'){
        setMensaje('Credenciales incorrectas o cuenta no verificada.');
      }
      else if(res==='ok'){
        setMensaje('');
        setDialogo(false);
      }
      setLoad(false);
      // setLoad(false);
      // navigate('/');
      // window.location.replace('/');
    } catch (err) {
      console.error(err);
    }
  }

  const handleRegister = async () =>{
    try {
      // console.log("xd");
      // CloseRegistro();
      // setEnvio(true);
      // setBloqueo(true);
      if(nombres === ''){
          setMSRegistro('Por favor ingrese sus nombres');
          return;
      }
      else if(apellidos === '' ){
        setMSRegistro('Por favor ingrese sus apellidos');
          return;
      }
      else if(numero === '' ){
        setMSRegistro('Por favor ingrese su número de documento');
          return;
      }
      else if(nacimiento === '' ){
        setMSRegistro('Por favor ingrese su fecha de nacimiento');
          return;
      }
      else if(!esMayorDeEdad(nacimiento)){
        setMSRegistro('Debes ser mayor de edad para poder registrarse');
          return;
      }
      else if(corr === '' || !validateEmail(corr)){
        setMSRegistro('Por favor ingrese un correo electrónico válido');
          return;
      }
      else if(con1 === ''){
        setMSRegistro('Por favor ingrese una contraseña');
          return;
      }
      else if(con2 === ''){
        setMSRegistro('Por favor confirme su contraseña');
          return;
      }
      else if(con1!==con2){
        setMSRegistro('Las contraseñas no coinciden');
          return;
      }
      else if(termino!==true){
        setMSRegistro('Por favor acepte los términos y condiciones');
          return;
      }

      setMSRegistro('');
      // Aqui deberiamos bloquear la pantalla para que cargue
      // Llamamos servicio de registro 
      
      const reqObj = {
        "email":corr,
        tipoDoc,
        "numeroDoc":numero,
        "con":con1,
        nombres,
        apellidos,
        nacimiento,
        tipoGenero,
        tipoShoe,
        celular
      };
  
      try {
        setBloqueo(true);
        const responseActive = await certifyAxios.post('/person/client/new/online', reqObj);
        setBloqueo(false);
        // console.log(responseActive);
        if(responseActive.data.estado==="ErrorDatos"){
          errorCreacion('Faltaron enviar datos obligatorios');
          // setConfirmacion(false);
        }
        else if(responseActive.data.estado==="ErrorCorreo"){
          errorCreacion('Este correo ya ha sido registrado previamente'); 
          // setConfirmacion(false);
        }
        else if(responseActive.data.estado==="ErrorDocumento"){
          errorCreacion('El número de documento ya se encuentra registrado'); 
          // setConfirmacion(false);
        }
        else if(responseActive.data.estado==="OK"){
          // MensajeOK('Se ha registrado correctamente al cliente');
          // setConfirmacion(false);
          CloseRegistro();
          setEnvio(true);
          // handleClose();
          // handlePage(1,busqueda);
          // getClients(1);
          // Actualizar clientes y volver a primera pagina
          
        }
        else{
          errorCreacion('Internal Server Error'); 
          // setConfirmacion(false);
        }
      } catch (error) {
        // console.error(error.response.data);
        setBloqueo(false);
        errorCreacion('Internal Server Error'); 
        // setConfirmacion(false);
      }


      
      // Aqui debemos llamar al registro
      // setLoad(true);
      // const res = await login(userx,pass);
      // if(res==='err'){
      //   setMensaje('Por favor haz click en “Olvidé mi contraseña” y crea una nueva.');
      // }
      // else if(res==='ok'){
      //   setMensaje('');
      //   setDialogo(false);
      // }
      // setLoad(false);
      // setLoad(false);
      // navigate('/');
      // window.location.replace('/');
    } catch (err) {
      console.error(err);
    }
  }

  const handleRecover = async () =>{
    try {
      // console.log("xd");
      // CloseRegistro();
      // setEnvio(true);
      // setBloqueo(true);
      if(userx === '' || !validateEmail(userx)){
        setMensaje('Por favor ingrese un correo válido');
        return;
    }
    // CloseDiaContra();
    // setEnvioCon(true);
   
      // setMSRegistro('');
      // Aqui deberiamos bloquear la pantalla para que cargue
      // Llamamos servicio de registro 
      
      const reqObj = {
        "email":userx,
      };
      try {
        setBloqueo(true);
        const responseActive = await certifyAxios.post('/person/client/recover/password', reqObj);
        setBloqueo(false);
        // console.log(responseActive);
        if(responseActive.data.estado==="ERRORNOTFOUND"){
          setMensaje('El correo proporcionado no esta asociado a ninguna cuenta.');
          return;
        }
        else if(responseActive.data.estado==="ERRORREPEAT"){
          setMensaje('Multiples registros del correo'); 
          return;
        }

        else if(responseActive.data.estado==="OK"){
          // MensajeOK('Se ha registrado correctamente al cliente');
          // setConfirmacion(false);
          CloseDiaContra();
          setEnvio(true);
          // handleClose();
          // handlePage(1,busqueda);
          // getClients(1);
          // Actualizar clientes y volver a primera pagina
          
        }
        else{
          errorCreacion('Internal Server Error'); 
          // console.log("asdasds");
          // setConfirmacion(false);
        }
      } catch (error) {
        console.log(error);
        setBloqueo(false);
        errorCreacion('Internal Server Error'); 
        // setConfirmacion(false);
      }


      
      // Aqui debemos llamar al registro
      // setLoad(true);
      // const res = await login(userx,pass);
      // if(res==='err'){
      //   setMensaje('Por favor haz click en “Olvidé mi contraseña” y crea una nueva.');
      // }
      // else if(res==='ok'){
      //   setMensaje('');
      //   setDialogo(false);
      // }
      // setLoad(false);
      // setLoad(false);
      // navigate('/');
      // window.location.replace('/');
    } catch (err) {
      console.error(err);
    }
  }


 const getShoes = async()=>{
    const reqObj = {
    };

  try{
  setBloqueo(true);
  const responseActive = await certifyAxios.post('/person/shoe', reqObj);
  setShoes(responseActive.data.list);
  setBloqueo(false);
  // const responseActive2 = await certifyAxios.post('/service/class/query/day', reqObj2);
  // console.log(responseActive2);
  }
  catch(ex){
    setBloqueo(false);
    console.log(ex);
  }
 }
 

// useEffect(() => {
//   const preventScroll = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     return false;
//   };

//   const isAnyDialogOpen = diaContra || dialogo || registro || envio || envioCon;

//   if (isAnyDialogOpen) {
//     document.body.style.overflow = 'hidden';
//     window.addEventListener('touchmove', preventScroll, { passive: false });
//   } else {
//     document.body.style.overflow = '';
//     window.removeEventListener('touchmove', preventScroll);
//   }

//   return () => {
//     document.body.style.overflow = '';
//     window.removeEventListener('touchmove', preventScroll);
//   };
// }, [diaContra, dialogo,registro, envio,envioCon]);

  useEffect(()=>{
    getShoes();
  },[]);

  // useEffect(()=>{
  //   console.log(user);
  // },[]);
// eslint-disable-next-line no-unused-vars
  const handleMenuClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchor(null);
  };
// eslint-disable-next-line no-unused-vars
const handleOptionSelect = (option) => {
  // Redirigir o realizar acción basada en la opción seleccionada
  if (option === "Clases") {
    window.location.href = "/classes";
  } else if (option === "Quienes Somos") {
    window.location.href = "/about_us";
  } else if (option === "Planes") {
    window.location.href = "/plans";
  }
  handleMenuClose();
};

// Renderizar el contenido del paso actual
const getStepContent = (step) => {
  switch (step) {
    case 0:
      return (
        <>
          <Box >
            <Box>
            <RequiredLabel text="Nombres" required />
              <TextField  fullWidth
                sx={{
                  // width:'250px',
                  mt:0.5,mb:0.5,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '7px',
                    height: 50,
                    border: '1px solid #C4C4C4',
  
                    ':hover': {
                      border: '0.5px solid #C4C4C4 !important',
                      boxShadow: '0px'
                    },
                    ':focus-within': { border: '0.5px solid #C4C4C4 !important' }
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    ':hover': {
                      border: '1px solid #C4C4C4 !important',
                      boxShadow: 'none'
                    }
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  }
                }}

                value={nombres} 
                    onChange={(event) =>
                      setNombres(event.target.value)
                  }
                />
                <RequiredLabel text="Apellidos" required />
              <TextField  fullWidth
                sx={{
                  // width:'100%',
                  // width:'250px',
                  mt:0.5,mb:0.5,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '7px',
                    height: 50,
                    border: '1px solid #C4C4C4',
  
                    ':hover': {
                      border: '0.5px solid #C4C4C4 !important',
                      boxShadow: '0px'
                    },
                    ':focus-within': { border: '0.5px solid #C4C4C4 !important' }
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    ':hover': {
                      border: '1px solid #C4C4C4 !important',
                      boxShadow: 'none'
                    }
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  }
                }}

                value={apellidos} 
                    onChange={(event) =>
                      setApellidos(event.target.value)
                  }
                />
                <RequiredLabel text="Fecha de nacimiento" required />
              <Box sx={{
                // width:'160px',
              border:'1px solid #C4C4C4',
                borderRadius:'10px',padding:0,mt:0.5,mb:0.5,height:50
              }}>
              <TextField fullWidth 
               sx={{
                "& [aria-expanded=true]":{
                  border:'none'
                },
                
               "&.Mui-focused": {
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      
                    },
                  },
                    ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                          },
              }}
              
              type="date" value={nacimiento} 
                    onChange={(event) =>
                      setNacimiento(event.target.value)
                  }
              />
              </Box> 
              <Box>
                <RequiredLabel text="Tpo. Documento" required />
              <FormControl fullWidth
              sx={{
                mb:0.5,
                border:'1px solid #C4C4C4',
                borderRadius:'10px',padding:0,mt:0.5,height:50
              }}>
                    <Select fullWidth
                    sx={{
                      "& [aria-expanded=true]":{
                        border:'none'
                      },
                      
                     "&.Mui-focused": {
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                            
                          },
                        },
                          ".MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                },
                    }}
                      value={tipoDoc}
                      onChange={(event) =>{
                          setTipoDoc(event.target.value);
                        
                      }
                    }
                    
                    >
                    {
                      Docs.map((cat)=>(
                        <MenuItem sx={{
                          "&.Mui-selected":{
                            backgroundColor:'transparent !important',
                            fontWeight:'normal',
                          },
                          "&:hover":{
                              backgroundColor:'#FFF000 !important'  
                          },
                          
                          color:`${alpha("#223354", 0.9)} !important`
                        }}
                        value={cat.id} key={cat.id}>{cat.nombre}</MenuItem>
                      ))
                    }
                </Select>
              </FormControl>
              <RequiredLabel text="Nro. Documento" required />
              <TextField  fullWidth
                sx={{
                  // width:'250px',
                  mt:0.5,mb:0.5,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '7px',
                    height: 50,
                    border: '1px solid #C4C4C4',
  
                    ':hover': {
                      border: '0.5px solid #C4C4C4 !important',
                      boxShadow: '0px'
                    },
                    ':focus-within': { border: '0.5px solid #C4C4C4 !important' }
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    ':hover': {
                      border: '1px solid #C4C4C4 !important',
                      boxShadow: 'none'
                    }
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  }
                }}

                value={numero} 
                    onChange={(event) =>{
                      const value = event.target.value;
                      if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
                        return;
                         }
                         if(value.length>35) return;
                         setNumero(event.target.value)
                    }                    
                  }
                />
             <Typography style={{fontWeight:'bold'}}>
                Género
              </Typography>
              <FormControl fullWidth 
              sx={{border:'1px solid #C4C4C4',
                borderRadius:'10px',padding:0,mt:0.5,mb:0.5,height:50
              }}>
                    <Select fullWidth
                    sx={{
                      "& [aria-expanded=true]":{
                        border:'none'
                      },
                      
                     "&.Mui-focused": {
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                            
                          },
                        },
                          ".MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                },
                    }}
                      value={tipoGenero}
                      onChange={(event) =>{
                          setTipoGenero(event.target.value);
                        
                      }
                    }
                    
                    >
                    {
                      Generos.map((cat)=>(
                        <MenuItem sx={{
                          "&.Mui-selected":{
                            backgroundColor:'transparent !important',
                            fontWeight:'normal',
                          },
                          "&:hover":{
                              backgroundColor:'#FFF000 !important'  
                          },
                          
                          color:`${alpha("#223354", 0.9)} !important`
                        }}
                        value={cat.id} key={cat.id}>{cat.nombre}</MenuItem>
                      ))
                    }
                </Select>
              </FormControl>
              <Typography style={{fontWeight:'bold'}}>
               Celular
              </Typography>
              <TextField fullWidth 
                sx={{
                  
                  mt:0.5,mb:0.5,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '7px',
                    height: 50,
                    border: '1px solid #C4C4C4',
  
                    ':hover': {
                      border: '0.5px solid #C4C4C4 !important',
                      boxShadow: '0px'
                    },
                    ':focus-within': { border: '0.5px solid #C4C4C4 !important' }
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    ':hover': {
                      border: '1px solid #C4C4C4 !important',
                      boxShadow: 'none'
                    }
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  }
                }}

                value={celular} 
                    onChange={(event) =>{
                      const value = event.target.value;
                      if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
                        return;
                         }
                         if(value.length>35) return;
                         setCelular(event.target.value)
                    }                    
                  }
                />
                 <Typography style={{fontWeight:'bold'}}>
                Talla de zapato
              </Typography>
              <FormControl fullWidth
                sx={{
                  
                  border: '1px solid #C4C4C4',
                  borderRadius: '10px',
                  padding: 0,
                  mt: 0.5, mb:0.5,
                  height: 50,
                }}
              >
                <Select
                  fullWidth
                  sx={{
                    "& [aria-expanded=true]": {
                      border: 'none',
                    },
                    "&.Mui-focused": {
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      border: 'none',
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: 'none',
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: 150, 
                        overflowY: 'auto', 
                        bgcolor: 'white', 
                      },
                    },
                  }}
                  value={tipoShoe}
                  onChange={(event) => {
                    setTipoShoe(event.target.value);
                  }}
                >
                  {Shoes.map((cat) => (
                    <MenuItem
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: 'transparent !important',
                          fontWeight: 'normal',
                        },
                        "&:hover": {
                          backgroundColor: '#FFF000 !important',
                        },
                        color: `${alpha("#223354", 0.9)} !important`,
                      }}
                      value={cat.id_talla_zapato}
                      key={cat.id_talla_zapato}
                    >
                      {cat.talla}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
               </Box>
            </Box>

            {/* <Button onClick={handleBack} disabled={activeStep === 0}>
              Anterior
            </Button>
            <Button
              onClick={activeStep === steps.length - 1 ? handleRegister : handleNext}
              variant="contained"
              color="primary"
            >
              {activeStep === steps.length - 1 ? 'Registrarse' : 'Siguiente'}
            </Button> */}
            { msregistro === '' ? (<></>):
            (
            <Typography style={{color:'#FF0000',fontSize:'13px'}}
             sx={{mt:0.5}}
            >
              {msregistro}      
            </Typography>)
          }
            <Button variant='contained' fullWidth sx={{mt:1.5}}
              onClick={()=>{handleNext(1);}}
              >
                CONTINUAR
              </Button>
          </Box>

          
        </>
      );
    case 1:
      return (
        <>
         <Button 
          variant="contained" 
          sx={{ mt: 0, p: 1, minWidth: 'auto' }} 
          onClick={handleBack}
        >
          <ArrowBackIcon />
        </Button>
          <Box 
              sx={{mt:1}}>
                <RequiredLabel text="Correo electrónico" required />
                <TextField fullWidth 
                sx={{
                  mt:1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '7px',
                    height: 50,
                    border: '1px solid #C4C4C4',
  
                    ':hover': {
                      border: '0.5px solid #C4C4C4 !important',
                      boxShadow: '0px'
                    },
                    ':focus-within': { border: '0.5px solid #C4C4C4 !important' }
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    ':hover': {
                      border: '1px solid #C4C4C4 !important',
                      boxShadow: 'none'
                    }
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  }
                }}

                value={corr} 
                    onChange={(event) =>
                      setCorr(event.target.value)
                  }
                />
            </Box>
            <Box>
            <Box>
              <RequiredLabel text="Contraseña" required sx={{mt:1}}/>
              <TextField
                fullWidth
                type="password"
                sx={{
                  mt: 1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '7px',
                    height: 50,
                    border: '1px solid #C4C4C4',
                    ':hover': {
                      border: '0.5px solid #C4C4C4 !important',
                      boxShadow: '0px',
                    },
                    ':focus-within': { border: '0.5px solid #C4C4C4 !important' },
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    ':hover': {
                      border: '1px solid #C4C4C4 !important',
                      boxShadow: 'none',
                    },
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
                value={con1}
                onChange={(event) => setCon1(event.target.value)}
              />
            </Box>

            <Box>
              <RequiredLabel text="Confirmar Contraseña" required sx={{mt:1}}/>
              <TextField
                fullWidth
                type="password"
                sx={{
                  mt: 1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '7px',
                    height: 50,
                    border: '1px solid #C4C4C4',
                    ':hover': {
                      border: '0.5px solid #C4C4C4 !important',
                      boxShadow: '0px',
                    },
                    ':focus-within': { border: '0.5px solid #C4C4C4 !important' },
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    ':hover': {
                      border: '1px solid #C4C4C4 !important',
                      boxShadow: 'none',
                    },
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
                value={con2}
                onChange={(event) => setCon2(event.target.value)}
              />
            </Box>
          </Box>

            { msregistro === '' ? (<></>):
            (
            <Typography style={{color:'#FF0000',fontSize:'13px'}}
             sx={{mt:1,mb:0}}
            >
              {msregistro}      
            </Typography>)
          }
            <FormGroup sx={{mt:1}}>
                <FormControlLabel control={<Checkbox  checked={termino}
                 onChange={() =>{
                        setTermino(!termino);
                       }
                    }
                    sx={{
                      color: "#DFE0EB",
                      "&.Mui-checked": {
                          color: "black"
                      },
                      '&:hover': {
                      backgroundColor: 'transparent !important'
                    }
                  }}
                />} 
                label={<Typography sx={{fontSize:'13px'}}>Aceptar Términos y Condiciones</Typography>}
                />
            </FormGroup>
            <Box sx={{mt:1.5}}>
              <Button variant='contained' fullWidth
              onClick={()=>{handleRegister();}}
             
              >
                  {
                   load === true ? (<>
                   <CircularProgress sx={{color:'black'}} size={20}/>
                   </>):('¡REGÍSTRATE YA!')
                  }
              </Button>
            </Box>
          
        </>
      );
    default:
      return 'Paso desconocido';
  }
};


const renderLoginContent = () => {
  return (
    <>
    <IconButton
      // onClick={()=>setOpen(!open)}
      ref={ButtonRef}
      onClick={()=>{
        setPop(!pop);
      }}
      size="large"
      sx={{ mr: 1 }}
      disableRipple
    >
       <img src={user.foto === null || user.foto === '' ? 'https://media.bclassclient.com/pr.png':user.foto} alt ='foto'
        style={{width:40,height:40,borderRadius:'100%'}}
       />
      <KeyboardArrowDownIcon sx={{ fontSize:20,color:'#FFFFFF'}}/>
    </IconButton>

   
    <Popper
      anchorEl={ButtonRef.current}
      transition
      // disablePortal
      placement='bottom-end'
      // transformOrigin={{vertical:'top',horizontal:'right'}}
      // anchorOrigin={{vertical:'bottom',horizontal:'right'}}
      // disableScrollLock
      // getContentAnchorEl={null}
      // id="account-menu"
      open={pop}
      // onClick={()=>{
      //   setPop(false);
      // }}
      
      // style={{marginLeft:'-6%'}} 
      >
  {({ TransitionProps, placement }) => (
        <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
     <ClickAwayListener onClickAway={handleClose}>
    <MenuList
      onClose={handleClick}
      // onClose={handleClose}
      onClick={()=>{setMenuOpen(false)}}
      
    >
      <MenuItem onClick={()=>{
        navigate('/profile/reserves')
      }}>
        Mis Reservas
      </MenuItem>
      <MenuItem onClick={()=>{
        navigate('/profile/memberships')
      }}>
        Mis Membresías
      </MenuItem>
      {/* <MenuItem onClick={handleClose}>
        Mis Productos
      </MenuItem> */}
      <MenuItem onClick={()=>{
        navigate('/profile/information')
      }}>
        Mi Información
      </MenuItem>
      {/* <MenuItem onClick={handleClose}>
        Mis Documentos
      </MenuItem> */}
      <Divider />
      <MenuItem onClick={handleLogout}>
        Cerrar Sesión
      </MenuItem>
      </MenuList>
      </ClickAwayListener> 
      </Paper>
      </Grow>
      )}  
      </Popper>
      </>
  );
}
  return (
    <>
    <BootstrapDialog
                  maxWidth="xs"
                  fullWidth
                  open={bloqueo}
                  // onClose={handleCloseConfirmacion}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    },
                  }}
                >
                  <DialogContent >
                    <Box style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
                      <CircularProgress color="primary" size="6rem"/>
                    </Box>
            </DialogContent>
        </BootstrapDialog>
     <Box
      sx={{
        display: "flex",
        justifyContent: isMobile ? "flex-end" : "flex-start",
        alignItems: "center",
        width: "100%",
      }}
    >
      { isMobile ? 
       (<>
       {isAuthenticated === false ? (
            <Button
            // startIcon={<ClassIcon size="1rem" />}
            size='medium'
            variant="outlined"
            color="white"
            // href="/"
            style={{
              // maxHeight:'40px',
              // width:'115px',
              // marginRight: 1,
              fontSize:'13px',
              color:'#000000',
              borderColor:'#000000',
              border:'2px solid'
            }}
            sx={{ mr:1,'&.MuiButton-root:hover':{bgcolor: 'transparent'} }}
            onClick={()=>{
              setDialogo(true);
            }}
          >
            INGRESAR
          </Button>
          )
        :(renderLoginContent())
        }
          
          <IconButton
      // onClick={()=>setOpen(!open)}
          ref={MenuRef}
          onClick={()=>{
            setPop2(!pop2);
          }}
          size="large"
          sx={{ mr: 1 }}
          disableRipple
        >
       {/* <img src={user.foto === null || user.foto === '' ? 'https://media.bclassclient.com/pr.png':user.foto} alt ='foto'
        style={{width:40,height:40,borderRadius:'100%'}}
       /> */}
      <MenuIcon sx={{ fontSize:20,color:'#FFFFFF'}}/>
    </IconButton>

   
        <Popper
          anchorEl={MenuRef.current}
          transition
          // disablePortal
          placement='bottom-end'
          // transformOrigin={{vertical:'top',horizontal:'right'}}
          // anchorOrigin={{vertical:'bottom',horizontal:'right'}}
          // disableScrollLock
          // getContentAnchorEl={null}
          // id="account-menu"
          open={pop2}
          // onClick={()=>{
          //   setPop(false);
          // }}
          
          // style={{marginLeft:'-6%'}} 
          >
      {({ TransitionProps, placement }) => (
            <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
        <ClickAwayListener onClickAway={handleClose2}>
        <MenuList
          onClose={handleClick}
          // onClose={handleClose}
          onClick={()=>{setMenuOpen(false)}}
          
        >
          <MenuItem onClick={()=>{
            navigate('/classes')
          }}>
            CLASES
          </MenuItem>
          <MenuItem onClick={()=>{
            navigate('/about_us')
          }}>
            QUIENES SOMOS
          </MenuItem>
          {/* <MenuItem onClick={handleClose}>
            Mis Productos
          </MenuItem> */}
          <MenuItem onClick={()=>{
            navigate('/plans')
          }}>
            PLANES
          </MenuItem>
          {/* <MenuItem onClick={handleClose}>
            Mis Documentos
          </MenuItem> */}
          {/* <Divider />
          <MenuItem onClick={handleLogout}>
            Cerrar Sesión
          </MenuItem> */}
          </MenuList>
          </ClickAwayListener> 
          </Paper>
          </Grow>
          )}  
          </Popper>
       </>) :(
       <>
       <Button
          // startIcon={<ClassIcon size="1rem" />}
          size="small"
          variant="outlined"
          color="white"
          href="/classes"
          style={{
            fontSize: '12px',
            fontWeight: 'bold',
            border: 0,
            ...(qu === "Clases" && {
              textDecoration: 'underline',
              color: 'black',
              textUnderlineOffset: '10px',
            }),
          }}
          sx={{ '&.MuiButton-root:hover': { bgcolor: 'transparent' } }}
        >
          CLASES
        </Button>
        
        <Button
          // startIcon={<ClassIcon size="1rem" />}
          variant="outlined"
          color="white"
          href="/about_us"
          style={{
            fontSize: '12px',
            fontWeight: 'bold',
            border: 0,
            ...(qu === "About_us" && {
              textDecoration: 'underline',
              color: 'black',
              textUnderlineOffset: '10px',
            }),
          }}
          sx={{ '&.MuiButton-root:hover': { bgcolor: 'transparent' } }}
        >
          QUIENES SOMOS
        </Button>
        <Button
          // startIcon={<ClassIcon size="1rem" />}
          size="small"
          variant="outlined"
          color="white"
          href="/plans"
          style={{
            marginRight: '14px',
            fontSize: '12px',
            fontWeight: 'bold',
            border: 0,
            ...(qu === "Plan" && {
              textDecoration: 'underline',
              color: 'black',
              textUnderlineOffset: '10px',
            }),
          }}
          sx={{ '&.MuiButton-root:hover': { bgcolor: 'transparent' } }}
        >
          PLANES
        </Button>
     

     { isAuthenticated === false ? (
          <Button
          // startIcon={<ClassIcon size="1rem" />}
          size='medium'
          variant="outlined"
          color="white"
          // href="/"
          style={{
            maxHeight:'40px',
            width:'115px',
            marginRight: '14px',
            fontSize:'13px',
            color:'#000000',
            borderColor:'#000000',
            border:'2px solid'
          }}
          sx={{ '&.MuiButton-root:hover':{bgcolor: 'transparent'} }}
          onClick={()=>{
            setDialogo(true);
          }}
        >
          INGRESAR
        </Button>
      ):(<></>)
      }


       { isAuthenticated === true ? (
      renderLoginContent()
      ):(<></>)
      }

      <Button
        // startIcon={<ClassIcon size="1rem" />}
        size='medium'
        variant="outlined"
        color="white"
        href="/plans"
        style={{
          width:'115px',
          marginRight: '23px',
          fontSize:'13px',
          border:0,
          backgroundColor:'#000000'
        }}
        sx={{ '&.MuiButton-root:hover':{bgcolor: 'transparent'} }}
      >
        COMPRAR
      </Button>
      </>
      )
    }
      {/* </div> */}
    </Box>
        
    {/* DIALOG INICIO DE SESION */}
    < Dialog
          open={dialogo}
          TransitionComponent={Transition}
        //   TransitionProps={{
        //     style: {
        //         transitionDelay: 500,
        //     }
        // }}
          fullWidth
          maxWidth="xs"
          // keepMounted
          onClose={CloseDialog}
          aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle sx={{ m: 0, p: 2,pb:1}} id="customized-dialog-title" style={{fontWeight:'bold'}}>
          Inicia Sesión
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={CloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
        <Box sx={{ pt:0.5
        // ,ml:7,mr:7
      }}      
        >
            <Box 
              sx={{mt:0}}>
                <Typography style={{fontWeight:'bold'}}>
                  Correo electrónico
                </Typography>
                <TextField fullWidth 
                sx={{
                  mt:1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '7px',
                    height: 50,
                    border: '1px solid #C4C4C4',
  
                    ':hover': {
                      border: '0.5px solid #C4C4C4 !important',
                      boxShadow: '0px'
                    },
                    ':focus-within': { border: '0.5px solid #C4C4C4 !important' }
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    ':hover': {
                      border: '1px solid #C4C4C4 !important',
                      boxShadow: 'none'
                    }
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  }
                }}

                value={userx} 
                    onChange={(event) =>
                      setUser(event.target.value)
                  }
                  onKeyPress= {(e) => {
                    if (e.key === 'Enter') {
                      handleLogIn();
                      // write your functionality here
                          }
                  }}
                />
            </Box>
            <Box 
              sx={{mt:2}}>
                <Typography style={{fontWeight:'bold'}}>
                  Contraseña
                </Typography>
                <TextField fullWidth  
                // sx={{mt:1}}
                type='password' 
                sx={{
                  mt:1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '7px',
                    height: 50,
                    border: '1px solid #C4C4C4',
  
                    ':hover': {
                      border: '0.5px solid #C4C4C4 !important',
                      boxShadow: '0px'
                    },
                    ':focus-within': { border: '0.5px solid #C4C4C4 !important' }
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    ':hover': {
                      border: '1px solid #C4C4C4 !important',
                      boxShadow: 'none'
                    }
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  }
                }}
                value={pass} 
                    onChange={(event) =>
                      setPass(event.target.value)
                  }
                  onKeyPress= {(e) => {
                    if (e.key === 'Enter') {
                      handleLogIn();
                      // write your functionality here
                          }
                  }}
                  />
            </Box>
            { mensaje === '' ? (<></>):
            (
            <Typography style={{color:'#FF0000',fontSize:'11px'}}>
              {mensaje}      
            </Typography>)
          }
            <Box sx={{mt:2,display:'flex',justifyContent:'right'}}>
            <Typography
      sx={{
        textDecoration: "none",
        fontWeight: "bold",
        fontSize: "11px",
      }}
    >
      <Button
        onClick={()=>{
          setUser('');
          setMensaje('');
          setDialogo(false);
          setDiaContra(true);
        }}
        style={{
          color: "black",
          textDecoration: "none",
          boxShadow: "none",
          background: "none",
          border: "none",
          padding: 0,
          fontSize: "inherit",
          fontWeight: "bold",
          cursor: "pointer",
        }}
      >
        ¿OLVIDASTE TU CONTRASEÑA?
      </Button>
    </Typography>
            </Box>
            <Box sx={{mt:2}}>
              <Button variant='contained' fullWidth
              onClick={()=>{handleLogIn();}}
             
              >
                  {
                   load === true ? (<>
                   <CircularProgress sx={{color:'black'}} size={20}/>
                   </>):('INICIAR SESIÓN')
                  }
              </Button>
            </Box>
        </Box>   
         
        </DialogContent>
        <DialogActions style={{backgroundColor:'#E5E5E5'}} sx={{padding:2}}>
          <Box style={{flex:'1 0 0'}}>
          <Typography align='center' style={{fontSize:'13px'}}>
            ¿Aún no tienes una cuenta? Regístrate &nbsp;
            <Link  style={{color:'black',textDecoration:'none',boxShadow:'none',fontWeight:'bolder',
              cursor:'pointer'
            }}
              onClick={()=>{
                CloseDialog();
                setRegistro(true);
              }}
            >
                  AQUÍ
                </Link>
          </Typography>
          {/* <Typography sx={{textDecoration: 'none',fontWeight:'bold',fontSize:'11px'}}>
              
          </Typography> */}
          {/* <Button autoFocus onClick={CloseDialog} variant='contained'>
            Cancelar
          </Button>
          <Button autoFocus 
          // onClick={creacionCliente} 
          variant='contained'>
            Guardar
          </Button> */}
          </Box>
        </DialogActions>
        </Dialog>
        {/* //////////// */}
     
    {/* DIALOG RECUPERAR CONTRASEÑA */}
    < Dialog
          open={diaContra}
          TransitionComponent={Transition}
        //   TransitionProps={{
        //     style: {
        //         transitionDelay: 500,
        //     }
        // }}
          fullWidth
          maxWidth="xs"
          // keepMounted
          onClose={CloseDiaContra}
          aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle sx={{ m: 0, p: 2,pb:1}} id="customized-dialog-title" style={{fontWeight:'bold'}}>
          ¿Has olvidado tu contraseña?
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={CloseDiaContra}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
        <Typography >
                 Por favor ingrese su correo electrónico. Enviaremos un correo para que pueda actualizar su contraseña.
          </Typography>
        <Box sx={{ pt:0.5
        // ,ml:7,mr:7
      }}      
        >
            <Box 
              sx={{mt:1}}>
               
                <Typography style={{fontWeight:'bold'}}>
                 Correo electrónico
                </Typography>
                <TextField fullWidth 
                sx={{
                  mt:1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '7px',
                    height: 50,
                    border: '1px solid #C4C4C4',
  
                    ':hover': {
                      border: '0.5px solid #C4C4C4 !important',
                      boxShadow: '0px'
                    },
                    ':focus-within': { border: '0.5px solid #C4C4C4 !important' }
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    ':hover': {
                      border: '1px solid #C4C4C4 !important',
                      boxShadow: 'none'
                    }
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  }
                }}

                value={userx} 
                    onChange={(event) =>
                      setUser(event.target.value)
                  }
                  onKeyPress= {(e) => {
                    if (e.key === 'Enter') {
                      handleRecover();
                      // write your functionality here
                          }
                  }}
                />
            </Box>
            { mensaje === '' ? (<></>):
            (
            <Typography style={{color:'#FF0000',fontSize:'11px'}}>
              {mensaje}      
            </Typography>)
          }
            {/* <Box sx={{mt:2,display:'flex',justifyContent:'right'}}>
            <Typography sx={{textDecoration: 'none',fontWeight:'bold',fontSize:'11px'}}>
                        <Link href="/" style={{color:'black',textDecoration:'none',boxShadow:'none'}}>
                            ¿OLVIDASTE TU CONTRASEÑA?
                          </Link>
                </Typography>
            </Box> */}
            <Box sx={{mt:2}}>
              <Button variant='contained' fullWidth
              onClick={()=>{handleRecover();}}
             
              >
                  {
                   load === true ? (<>
                   <CircularProgress sx={{color:'black'}} size={20}/>
                   </>):('ENVIAR CORREO')
                  }
              </Button>
            </Box>
        </Box>   
         
        </DialogContent>
        <DialogActions style={{backgroundColor:'#E5E5E5'}} sx={{padding:2}}>
          <Box style={{flex:'1 0 0'}}>
          <Typography align='center' style={{fontSize:'13px'}}>
            ¿Volver al inicio de sesión? Vuelva &nbsp;
            <Link  style={{color:'black',textDecoration:'none',boxShadow:'none',fontWeight:'bolder',
              cursor:'pointer'
            }}
              onClick={()=>{
                CloseDiaContra();
                setDialogo(true);
                // setRegistro(true);
              }}
            >
                  AQUÍ
                </Link>
          </Typography>
          {/* <Typography sx={{textDecoration: 'none',fontWeight:'bold',fontSize:'11px'}}>
              
          </Typography> */}
          {/* <Button autoFocus onClick={CloseDialog} variant='contained'>
            Cancelar
          </Button>
          <Button autoFocus 
          // onClick={creacionCliente} 
          variant='contained'>
            Guardar
          </Button> */}
          </Box>
        </DialogActions>
        </Dialog>
        {/* //////////// */}

     {/* DIALOG DE REGISTRO, el registro es muy grande para celulares, voy a partirlo en 2 pantallas */}
    < Dialog
          open={registro}
          TransitionComponent={Transition}
          fullWidth
          fullScreen={isMobile}
          maxWidth={isMobile ? 'xs' : 'sm'}
          PaperProps={{
            sx: {
              maxHeight: isMobile ? '100%' : '90vh'
            }
          }}
          onClose={CloseRegistro}
          aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle sx={{ m: 0, p: 2,pb:1}} id="customized-dialog-title" style={{fontWeight:'bold'}}>
          Regístrate
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={(CloseRegistro)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {isMobile ? (<>
            <>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 0,mt:0,backgroundColor:'transparent',pt:0,pb:1}}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {getStepContent(activeStep)}
          </>
          </>):(
          <>
          <Box sx={{ pt:0.5}} >
          <Box style={{display:'flex',justifyContent:'space-between'}} >
            <Box >
            <RequiredLabel text="Nombres" required />
              <TextField  
                sx={{
                  width:'250px',
                  mt:1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '7px',
                    height: 50,
                    border: '1px solid #C4C4C4',
  
                    ':hover': {
                      border: '0.5px solid #C4C4C4 !important',
                      boxShadow: '0px'
                    },
                    ':focus-within': { border: '0.5px solid #C4C4C4 !important' }
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    ':hover': {
                      border: '1px solid #C4C4C4 !important',
                      boxShadow: 'none'
                    }
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  }
                }}

                value={nombres} 
                    onChange={(event) =>
                      setNombres(event.target.value)
                  }
                />
            </Box>
            <Box>
            <RequiredLabel text="Apellidos" required />
              <TextField  
                sx={{
                  // width:'100%',
                  width:'250px',
                  mt:1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '7px',
                    height: 50,
                    border: '1px solid #C4C4C4',
  
                    ':hover': {
                      border: '0.5px solid #C4C4C4 !important',
                      boxShadow: '0px'
                    },
                    ':focus-within': { border: '0.5px solid #C4C4C4 !important' }
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    ':hover': {
                      border: '1px solid #C4C4C4 !important',
                      boxShadow: 'none'
                    }
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  }
                }}

                value={apellidos} 
                    onChange={(event) =>
                      setApellidos(event.target.value)
                  }
                />
            </Box>
          </Box>

          <Box style={{display:'flex',justifyContent:'space-between'}} sx={{mt:1.5}}>
            <Box >
            <RequiredLabel text="Tpo. Documento" required />
              <FormControl sx={{width:'120px',border:'1px solid #C4C4C4',
                borderRadius:'10px',padding:0,mt:1,height:50
              }}>
                    <Select fullWidth
                    sx={{
                      "& [aria-expanded=true]":{
                        border:'none'
                      },
                      
                     "&.Mui-focused": {
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                            
                          },
                        },
                          ".MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                },
                    }}
                      value={tipoDoc}
                      onChange={(event) =>{
                          setTipoDoc(event.target.value);
                        
                      }
                    }
                    
                    >
                    {
                      Docs.map((cat)=>(
                        <MenuItem sx={{
                          "&.Mui-selected":{
                            backgroundColor:'transparent !important',
                            fontWeight:'normal',
                          },
                          "&:hover":{
                              backgroundColor:'#FFF000 !important'  
                          },
                          
                          color:`${alpha("#223354", 0.9)} !important`
                        }}
                        value={cat.id} key={cat.id}>{cat.nombre}</MenuItem>
                      ))
                    }
                </Select>
              </FormControl>
            </Box>
            <Box>
            <RequiredLabel text="Nro. Documento" required />
              <TextField  
                sx={{
                  width:'200px',
                  mt:1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '7px',
                    height: 50,
                    border: '1px solid #C4C4C4',
  
                    ':hover': {
                      border: '0.5px solid #C4C4C4 !important',
                      boxShadow: '0px'
                    },
                    ':focus-within': { border: '0.5px solid #C4C4C4 !important' }
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    ':hover': {
                      border: '1px solid #C4C4C4 !important',
                      boxShadow: 'none'
                    }
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  }
                }}

                value={numero} 
                    onChange={(event) =>{
                      const value = event.target.value;
                      if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
                        return;
                         }
                         if(value.length>35) return;
                         setNumero(event.target.value)
                    }                    
                  }
                />
            </Box>
            <Box >
            <RequiredLabel text="Fecha de nacimiento" required />
              <Box sx={{width:'160px',border:'1px solid #C4C4C4',
                borderRadius:'10px',padding:0,mt:1,height:50
              }}>
              <TextField fullWidth 
               sx={{
                "& [aria-expanded=true]":{
                  border:'none'
                },
                
               "&.Mui-focused": {
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      
                    },
                  },
                    ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                          },
              }}
              
              type="date" value={nacimiento} 
                    onChange={(event) =>
                      setNacimiento(event.target.value)
                  }
              />
              </Box>
            </Box>
          </Box>


          <Box style={{display:'flex',justifyContent:'space-between'}} sx={{mt:2}}>
            <Box >
              <Typography style={{fontWeight:'bold'}}>
                Género
              </Typography>
              <FormControl sx={{width:'120px',border:'1px solid #C4C4C4',
                borderRadius:'10px',padding:0,mt:1,height:50
              }}>
                    <Select fullWidth
                    sx={{
                      "& [aria-expanded=true]":{
                        border:'none'
                      },
                      
                     "&.Mui-focused": {
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                            
                          },
                        },
                          ".MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                },
                    }}
                      value={tipoGenero}
                      onChange={(event) =>{
                          setTipoGenero(event.target.value);
                        
                      }
                    }
                    
                    >
                    {
                      Generos.map((cat)=>(
                        <MenuItem sx={{
                          "&.Mui-selected":{
                            backgroundColor:'transparent !important',
                            fontWeight:'normal',
                          },
                          "&:hover":{
                              backgroundColor:'#FFF000 !important'  
                          },
                          
                          color:`${alpha("#223354", 0.9)} !important`
                        }}
                        value={cat.id} key={cat.id}>{cat.nombre}</MenuItem>
                      ))
                    }
                </Select>
              </FormControl>
            </Box>
            <Box>
             <Typography style={{fontWeight:'bold'}}>
               Celular
              </Typography>
              <TextField  
                sx={{
                  width:'200px',
                  mt:1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '7px',
                    height: 50,
                    border: '1px solid #C4C4C4',
  
                    ':hover': {
                      border: '0.5px solid #C4C4C4 !important',
                      boxShadow: '0px'
                    },
                    ':focus-within': { border: '0.5px solid #C4C4C4 !important' }
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    ':hover': {
                      border: '1px solid #C4C4C4 !important',
                      boxShadow: 'none'
                    }
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  }
                }}

                value={celular} 
                    onChange={(event) =>{
                      const value = event.target.value;
                      if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
                        return;
                         }
                         if(value.length>35) return;
                         setCelular(event.target.value)
                    }                    
                  }
                />
            </Box>
            <Box >
              <Typography style={{fontWeight:'bold'}}>
                Talla de zapato
              </Typography>
              <FormControl
                sx={{
                  width: '160px',
                  border: '1px solid #C4C4C4',
                  borderRadius: '10px',
                  padding: 0,
                  mt: 1,
                  height: 50,
                }}
              >
                <Select
                  fullWidth
                  sx={{
                    "& [aria-expanded=true]": {
                      border: 'none',
                    },
                    "&.Mui-focused": {
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      border: 'none',
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: 'none',
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: 150, 
                        overflowY: 'auto', 
                        bgcolor: 'white', 
                      },
                    },
                  }}
                  value={tipoShoe}
                  onChange={(event) => {
                    setTipoShoe(event.target.value);
                  }}
                >
                  {Shoes.map((cat) => (
                    <MenuItem
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: 'transparent !important',
                          fontWeight: 'normal',
                        },
                        "&:hover": {
                          backgroundColor: '#FFF000 !important',
                        },
                        color: `${alpha("#223354", 0.9)} !important`,
                      }}
                      value={cat.id_talla_zapato}
                      key={cat.id_talla_zapato}
                    >
                      {cat.talla}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

            <Box 
              sx={{mt:2}}>
                <RequiredLabel text="Correo electrónico" required />
                <TextField fullWidth 
                sx={{
                  mt:1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '7px',
                    height: 50,
                    border: '1px solid #C4C4C4',
  
                    ':hover': {
                      border: '0.5px solid #C4C4C4 !important',
                      boxShadow: '0px'
                    },
                    ':focus-within': { border: '0.5px solid #C4C4C4 !important' }
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    ':hover': {
                      border: '1px solid #C4C4C4 !important',
                      boxShadow: 'none'
                    }
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  }
                }}

                value={corr} 
                    onChange={(event) =>
                      setCorr(event.target.value)
                  }
                />
            </Box>
            <Box 
            sx={{
              mt: 2,
              display: 'flex',
              gap: 2,
              alignItems: 'flex-start',
            }}
          >
            <Box sx={{ flex: 1 }}>
              <RequiredLabel text="Contraseña" required />
              <TextField
                fullWidth
                type="password"
                sx={{
                  mt: 1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '7px',
                    height: 50,
                    border: '1px solid #C4C4C4',
                    ':hover': {
                      border: '0.5px solid #C4C4C4 !important',
                      boxShadow: '0px',
                    },
                    ':focus-within': { border: '0.5px solid #C4C4C4 !important' },
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    ':hover': {
                      border: '1px solid #C4C4C4 !important',
                      boxShadow: 'none',
                    },
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
                value={con1}
                onChange={(event) => setCon1(event.target.value)}
              />
            </Box>

            <Box sx={{ flex: 1 }}>
              <RequiredLabel text="Confirmar Contraseña" required />
              <TextField
                fullWidth
                type="password"
                sx={{
                  mt: 1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '7px',
                    height: 50,
                    border: '1px solid #C4C4C4',
                    ':hover': {
                      border: '0.5px solid #C4C4C4 !important',
                      boxShadow: '0px',
                    },
                    ':focus-within': { border: '0.5px solid #C4C4C4 !important' },
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled': {
                    ':hover': {
                      border: '1px solid #C4C4C4 !important',
                      boxShadow: 'none',
                    },
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
                value={con2}
                onChange={(event) => setCon2(event.target.value)}
              />
            </Box>
          </Box>

            { msregistro === '' ? (<></>):
            (
            <Typography style={{color:'#FF0000',fontSize:'13px'}}
             sx={{mt:2}}
            >
              {msregistro}      
            </Typography>)
          }
            <FormGroup sx={{mt:1}}>
                <FormControlLabel control={<Checkbox  checked={termino}
                 onChange={() =>{
                        setTermino(!termino);
                       }
                    }
                    sx={{
                      color: "#DFE0EB",
                      "&.Mui-checked": {
                          color: "black"
                      },
                      '&:hover': {
                      backgroundColor: 'transparent !important'
                    }
                  }}
                />} 
                label={<Typography sx={{fontSize:'13px'}}>Aceptar Términos y Condiciones</Typography>}
                />
            </FormGroup>
            <Box sx={{mt:2}}>
              <Button variant='contained' fullWidth
              onClick={()=>{handleRegister();}}
             
              >
                  {
                   load === true ? (<>
                   <CircularProgress sx={{color:'black'}} size={20}/>
                   </>):('¡REGÍSTRATE YA!')
                  }
              </Button>
            </Box>
        </Box>   
          </>
          )
          }
        </DialogContent>
        <DialogActions style={{backgroundColor:'#E5E5E5'}} sx={{padding:2}}>
          <Box style={{flex:'1 0 0'}}>
          <Typography align='center' style={{fontSize:'13px'}}>
            ¿Ya tienes una cuenta? &nbsp;
            <Link  style={{color:'black',textDecoration:'none',boxShadow:'none',fontWeight:'bolder',
              cursor:'pointer'
            }} onClick={()=>
            {
              setRegistro(false);
              CloseRegistro();
              setDialogo(true);
            }
            }
            >
                  INICIA SESIÓN AQUÍ
                </Link>
          </Typography>
          </Box>
        </DialogActions>
        </Dialog>
        {/* //////////// */}



      {/* DIALOG ENVIO DE CORREO VERIFICACION */}
    < Dialog
          open={envio}
          TransitionComponent={Transition}
        //   TransitionProps={{
        //     style: {
        //         transitionDelay: 500,
        //     }
        // }}
          fullWidth
          maxWidth="xs"
          // keepMounted
          onClose={CloseEnvio}
          aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle sx={{ m: 0, p: 2,pb:1}} id="customized-dialog-title" style={{fontWeight:'bold',fontSize:'17px'}}>
          Verifique su cuenta
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={CloseEnvio}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
        <Box sx={{ pt:0.5
        // ,ml:7,mr:7
      }}      
        >
            <Box 
              sx={{mt:0}}>
                <Box style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
                    <EmailIcon sx={{height:100,width:100}}/>
                </Box>
                <Typography align='center' sx={{fontSize:'17px'}}>
                  Hemos enviado un enlace verificación a su correo, por favor verifique su bandeja de entrada y haga click en el enlace para verificar su cuenta.
                </Typography>
            </Box>
           </Box>
        </DialogContent>
        
        </Dialog>
        {/* //////////// */}

          {/* DIALOG ENVIO DE CORREO CONTRASEÑA */}
    < Dialog
          open={envioCon}
          TransitionComponent={Transition}
        //   TransitionProps={{
        //     style: {
        //         transitionDelay: 500,
        //     }
        // }}
          fullWidth
          maxWidth="xs"
          // keepMounted
          onClose={CloseEnvioCon}
          aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle sx={{ m: 0, p: 2,pb:1}} id="customized-dialog-title" style={{fontWeight:'bold',fontSize:'17px'}}>
          Correo de recuperación
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={CloseEnvioCon}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
        <Box sx={{ pt:0.5
        // ,ml:7,mr:7
      }}      
        >
            <Box 
              sx={{mt:0}}>
                <Box style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
                    <EmailIcon sx={{height:100,width:100}}/>
                </Box>
                <Typography align='center' sx={{fontSize:'17px'}}>
                  Hemos enviado un enlace de recuperación a su correo, por favor verifique su bandeja de entrada y haga click en el enlace para cambiar su contraseña.
                </Typography>
            </Box>
           </Box>
        </DialogContent>
        
        </Dialog>
        {/* //////////// */}

</>
  );
}

export default HeaderButtons;